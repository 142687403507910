import { RootState } from '@shared/store';
import { createProjectSlice } from './project-create.reducer';
import { createSelector } from '@reduxjs/toolkit';

const selectProjectCreation = (state: RootState) =>
  state[createProjectSlice.name];

const createProjectResponse = createSelector(
  selectProjectCreation,
  state => state.status
);

const activeProject = createSelector(
  selectProjectCreation,
  state => state.projectData
);

export const ProjectCreationSelectors = {
  createProjectResponse,
  activeProject
};
