import { ApiRequestStatus } from '@app/models/api/api-request-status.type';
import { createSlice } from '@reduxjs/toolkit';
import { ProjectListActions } from './project-list.actions';
import { Project, ProjectList } from '@app/shared/types/project.type';
import { ApiError } from '@app/models/api/api-response.interface';

export interface ProjectState {
  projectList: ProjectList;
  status: ApiRequestStatus;
  error?: ApiError | null;
  activeProject: Project | null;
}

const initialState: ProjectState = {
  projectList: [],
  status: 'idle',
  error: null,
  activeProject: null
};
export const projectListSlice = createSlice({
  name: 'project-list',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(ProjectListActions.fetchProjectLists, state => ({
        ...state,
        status: 'loading'
      }))
      .addCase(
        ProjectListActions.fetchProjectListsSuccess,
        (state, action) => ({
          ...state,
          status: 'succeeded',
          projectList: action.payload.projectsList,
          activeProject:
            action.payload.projectsList &&
            action.payload.projectsList.length > 0
              ? action.payload.projectsList[0]
              : null
        })
      )
      .addCase(ProjectListActions.fetchProjectListsFailed, (state, action) => ({
        ...state,
        status: 'failed',
        error: action.payload.error
      }))
      .addCase(ProjectListActions.setActiveProject, (state, action) => ({
        ...state,
        activeProject: action.payload.activeProject
      }))
      .addCase(ProjectListActions.fetchProjectByIdAttempt, state => ({
        ...state,
        activeProject: null,
        status: 'loading'
      }))
      .addCase(ProjectListActions.fetchProjectByIdSuccess, (state, action) => ({
        ...state,
        activeProject: action.payload.projectData,
        status: 'succeeded'
      }))
      .addCase(ProjectListActions.fetchProjectByIdFailed, state => ({
        ...state,
        status: 'failed'
      }));
  }
});
