import { Provider } from 'react-redux';
import { store } from '../../store';
import React from 'react';

interface RootStoreProviderProps {
  children: React.ReactNode;
}

export const RootStoreProvider = ({ children }: RootStoreProviderProps) => {
  return <Provider store={store}>{children}</Provider>;
};
