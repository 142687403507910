import lightImg from 'assets/img/spot-illustrations/1.png';
import darkImg from 'assets/img/spot-illustrations/dark_1.png';
import { Link } from 'react-router-dom';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const AccountCreateStatus = ({
  layout
}: {
  layout: 'simple' | 'card' | 'split';
}) => {
  return (
    <div className="text-center mb-6 mx-auto">
      <img className="mb-7 d-dark-none" src={lightImg} alt="phoenix" />
      <img className="mb-7 d-light-none" src={darkImg} alt="phoenix" />
      <div className="mb-6">
        <h4 className="text-body-highlight">Success</h4>
        <p className="text-body-tertiary">
          Your Account has been created. <br className="d-lg-none" />
          You can login with your credentials
        </p>
      </div>
      <div aria-activedescendant={layout.toString()} className="d-grid">
        <Button
          variant="primary"
          as={Link}
          to={`/`}
          startIcon={<FontAwesomeIcon icon={faAngleLeft} className="me-2" />}
        >
          Go to sign in page
        </Button>
      </div>
    </div>
  );
};

export default AccountCreateStatus;
