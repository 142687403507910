import { createSlice } from '@reduxjs/toolkit';
import { Project } from '@shared/types/project.type';
import { ApiRequestStatus } from '@app/models/api/api-request-status.type';
import { ProjectCreateActions } from './project-create.actions';

interface createProjectState {
  projectData: Project | null;
  status: ApiRequestStatus;
}
const initialState: createProjectState = {
  projectData: null,
  status: 'idle'
};
export const createProjectSlice = createSlice({
  name: 'createProject',
  initialState: initialState,
  reducers: {},
  extraReducers: builder =>
    builder
      .addCase(ProjectCreateActions.createProjectAttempt, state => ({
        ...state,
        projectData: null,
        status: 'loading'
      }))
      .addCase(ProjectCreateActions.createProjectSuccess, (state, action) => ({
        ...state,
        status: 'succeeded',
        projectData: action.payload.projectData
      }))
      .addCase(ProjectCreateActions.createProjectFailed, state => ({
        ...state,
        status: 'failed'
      }))
});
