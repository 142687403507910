import { createAction } from '@reduxjs/toolkit';
import { Project, ProjectCreateRequestInput } from '@shared/types/project.type';
import { ApiError } from '@app/models/api/api-response.interface';

const createProjectAttempt = createAction<ProjectCreateRequestInput>(
  '[Create Project] Create Project Attempt'
);

const createProjectSuccess = createAction<{
  projectData: Project;
}>('[Create Project] Create Project Success');

const createProjectFailed = createAction<{ error: ApiError }>(
  '[Create Project] Create Project Failed'
);
export const ProjectCreateActions = {
  createProjectAttempt,
  createProjectSuccess,
  createProjectFailed
};
