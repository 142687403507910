import { createSelector } from '@reduxjs/toolkit';
import { RootState } from './../../../../app/shared/store';
import { accountSlice } from './account.reducer';

const selectUserSlice = (state: RootState) => state[accountSlice.name];

const userResponse = createSelector(selectUserSlice, state => state.userData);

const createUserRequestStatus = createSelector(
  selectUserSlice,
  state => state.status
);

export const UserSelector = {
  userResponse,
  createUserRequestStatus
};
