import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/shared/store';
import { projectListSlice } from './project-list.reducer';

const allProjectsSlice = (state: RootState) => state[projectListSlice.name];

const allProjects = createSelector(
  allProjectsSlice,
  state => state.projectList
);

const activeProject = createSelector(
  allProjectsSlice,
  state => state.activeProject
);

export const ProjectSelector = {
  allProjects,
  activeProject
};
