import React, { CSSProperties } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { useAppContext } from 'providers/AppProvider';
import { TooltipComponent } from 'echarts/components';
import { BarChart } from 'echarts/charts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { tooltipFormatterDefault } from 'helpers/echart-utils';
import { ChartData } from '@shared/types/project.type';

echarts.use([TooltipComponent, BarChart]);

const ContactsCreatedChart = ({
  style,
  chartData
}: {
  style: CSSProperties;
  chartData: ChartData | undefined;
}) => {
  const { getThemeColor } = useAppContext();
  const getDefaultOptions = (getThemeColor: (name: string) => string) => ({
    color: [getThemeColor('primary'), getThemeColor('tertiary-bg')],
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getThemeColor('body-highlight-bg'),
      borderColor: getThemeColor('tertiary-bg'),
      textStyle: { color: getThemeColor('light-text-emphasis') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      },
      formatter: (params: CallbackDataParams[]) =>
        tooltipFormatterDefault(params, 'MMM DD', 'color')
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        color: getThemeColor('secondary-color'),
        // formatter: (value: string) => dayjs(value).format('D MMM, YY'),
        fontFamily: 'Nunito Sans',
        fontWeight: 600,
        fontSize: 10.24,
        padding: [0, 0, 0, 20]
      },
      splitLine: {
        show: true,
        interval: '10',
        lineStyle: {
          color: getThemeColor('tertiary-bg')
        }
      },
      show: true,
      interval: 10,
      data: chartData?.xAxis,
      axisLine: {
        lineStyle: {
          color: getThemeColor('tertiary-bg')
        }
      },
      axisTick: false
    },
    yAxis: {
      axisPointer: { type: 'none' },
      position: 'right',
      axisTick: 'none',
      splitLine: {
        interval: 5,
        lineStyle: {
          color: getThemeColor('secondary-bg')
        }
      },
      axisLine: { show: false },
      axisLabel: {
        fontFamily: 'Nunito Sans',
        fontWeight: 700,
        fontSize: 12.8,
        color: getThemeColor('body-color'),
        margin: 20,
        verticalAlign: 'bottom',
        formatter: (value: string) => `${value.toLocaleString()}`
      }
    },
    series: [
      {
        name: 'Disparity Ratio ',
        type: 'bar',
        data: chartData?.yAxis,
        barWidth: '10px',
        barGap: '3',
        label: {
          show: false,
          position: 'top',
          color: getThemeColor('primary'),
          fontWeight: 'bold',
          fontSize: '12.24px'
        },
        emphasis: {
          label: {
            show: true
          }
        },
        z: 10,
        itemStyle: {
          borderRadius: [2, 2, 0, 0],
          color: getThemeColor('primary')
        }
      }
    ],
    grid: {
      right: 3,
      left: 6,
      bottom: 0,
      top: '5%',
      containLabel: true
    },
    animation: false
  });

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getDefaultOptions(getThemeColor)}
      style={style}
    />
  );
};

export default ContactsCreatedChart;
