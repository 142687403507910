import { ApiRequestStatus } from '@app/models/api/api-request-status.type';
import { createSlice } from '@reduxjs/toolkit';
import { ToDo, UserResponse } from '@app/shared/types/user.types';
import { UserAccountActions } from './account.actions';

export interface AccountState {
  userData: UserResponse | null;
  userToDos: ToDo | null;
  status: ApiRequestStatus;
}
const initialState: AccountState = {
  userData: null,
  userToDos: null,
  status: 'idle'
};
export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(UserAccountActions.fetchUserAccountAttempt, state => ({
        ...state,
        status: 'loading'
      }))
      .addCase(UserAccountActions.fetchUserAccountSuccess, (state, action) => ({
        ...state,
        status: 'succeeded',
        userData: action.payload.userData
      }))
      .addCase(UserAccountActions.fetchUserAccountFailed, state => ({
        ...state,
        status: 'failed',
        userData: null
      }))
      .addCase(UserAccountActions.createUserAccountAttempt, state => ({
        ...state,
        status: 'loading'
      }))
      .addCase(
        UserAccountActions.createUserAccountSuccess,
        (state, action) => ({
          ...state,
          userData: action.payload.userResponse,
          status: 'succeeded'
        })
      )
      .addCase(UserAccountActions.createUserAccountFailed, state => ({
        ...state,
        status: 'failed',
        userData: null
      }));
  }
});
