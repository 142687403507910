import MainLayout from 'layouts/MainLayout';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import Ecommerce from 'pages/dashboard/ecommerce';
import CreateNew from './pages/project-management/create-project/CreateNew';
import ProjectListView from 'pages/project-management/project-list/ProjectListView';
import ProjectDetails from './pages/project-management/project-details/ProjectDetails';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import Error404 from 'pages/error/Error404';
import Error403 from 'pages/error/Error403';
import Error500 from 'pages/error/Error500';
import SimpleSignIn from 'pages/authentication/SignIn';
import SimpleSignUp from 'pages/authentication/SignUp';
import AccountCreateStatus from 'pages/authentication/AccountCreateStatus';
import SimpleResetPassword from 'pages/authentication/ResetPassword';
import SimpleLockScreen from 'pages/authentication/LockScreen';
import SimpleTwoFA from 'pages/authentication/TwoFA';
import SimpleForgotPassword from 'pages/authentication/ForgotPassword';
import Showcase from 'pages/Showcase';

import App from 'App';
import Crm from 'pages/dashboard/Crm';
import TravelAgency from 'pages/dashboard/TravelAgency';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/project',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <Ecommerce />
          },
          {
            path: '/project',
            children: [
              {
                path: 'dashboard',
                element: <Crm />
              },
              {
                path: 'create-project',
                element: <CreateNew />
              },
              {
                path: 'all-projects',
                element: <ProjectListView />
              },
              {
                path: 'crm',
                element: <Crm />
              },
              {
                path: 'travel-agency',
                element: <TravelAgency />
              }
            ]
          },
          {
            path: '/project/:projectId',
            children: [
              {
                path: 'details',
                element: <ProjectDetails />
              },
              {
                path: 'dashboard',
                element: <Crm />
              }
            ]
          }
        ]
      },
      {
        path: '/pages/errors/',
        children: [
          {
            path: '404',
            element: <Error404 />
          },
          {
            path: '403',
            element: <Error403 />
          },
          {
            path: '500',
            element: <Error500 />
          }
        ]
      },
      {
        path: '/',

        children: [
          {
            index: true,
            element: <SimpleSignIn />
          },
          {
            path: 'sign-up',
            element: <SimpleSignUp />
          },
          {
            path: 'account-create-status',
            element: <AccountCreateStatus />
          },
          {
            path: 'forgot-password',
            element: <SimpleForgotPassword />
          },
          {
            path: 'reset-password',
            element: <SimpleResetPassword />
          },
          {
            path: 'lock-screen',
            element: <SimpleLockScreen />
          },
          {
            path: '2FA',
            element: <SimpleTwoFA />
          }
        ]
      },

      {
        path: 'showcase',
        element: <Showcase />
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
