import DealForecastTable from 'components/tables/DealForecastTable';
import React from 'react';
import { Result } from '@shared/types/project.type';

interface DealForecastProps {
  data: Result[];
  title: string;
}

const DealForecast: React.FC<DealForecastProps> = ({ data, title }) => {
  return (
    <>
      {/*<DealForecasrProgressBar />*/}
      <h4 className="mt-4 mb-3">{title}</h4>
      <DealForecastTable tableData={data} />
    </>
  );
};

export default DealForecast;
