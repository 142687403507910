/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import { Col, Nav, ProgressBar, Row, Tab } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import document from '../../assets/img/document.png';
import trash from '../../assets/img/delete.png';
import classNames from 'classnames';
import { FileUpload } from 'primereact/fileupload';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectCreationSelectors } from '../../pages/project-management/create-project/data-access/+state/project-create.selectors';
import { FileUploadInput, UploadType } from '@shared/types/project.type';
import { uploadFile } from '../../pages/project-management/create-project/data-access/services/fileUpload.service';
import { useRef, useState } from 'react';
import { Tag } from 'primereact/tag';
import { setToast } from '../Toasts/data-access/toast-reducer';

type NavItemType = {
  label: string;
  icon: string;
  eventKey: string;
};

const NavItem = ({ item, isLast }: { item: NavItemType; isLast?: boolean }) => {
  return (
    <Nav.Link
      eventKey={item.eventKey}
      className={classNames(
        'text-center text-sm-start cursor-pointer outline-none d-sm-flex align-items-sm-center',
        {
          'border-bottom-sm border-end border-end-sm-0': !isLast
        }
      )}
    >
      <FeatherIcon icon={item.icon} size={16} className="me-sm-2 nav-icons" />
      <span className="d-none d-sm-inline">{item.label}</span>
    </Nav.Link>
  );
};

const navItems: NavItemType[] = [
  {
    label: 'Dataset',
    eventKey: 'dataset',
    icon: 'tag'
  },
  {
    label: 'ML Model',
    icon: 'package',
    eventKey: 'model'
  },
  {
    label: 'LLM Model',
    icon: 'truck',
    eventKey: 'llm'
  },
  {
    label: 'Policy Documents',
    icon: 'globe',
    eventKey: 'privacy'
  }
];

const InventoryTab = () => {
  const projectData = useSelector(ProjectCreationSelectors.activeProject);
  const [totalSize, setTotalSize] = useState(0);
  const [progress, setProgress] = useState(0);
  const fileUploadRef = useRef<FileUpload>(null);
  const dispatch = useDispatch();

  const onTemplateSelect = (event: { files: File[] }) => {
    let _totalSize = totalSize;
    const files = event.files;

    files.forEach(file => {
      _totalSize += file.size;
    });
    setProgress(0);
    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (event: { files: File[] }) => {
    let _totalSize = 0;
    setProgress(0);
    event.files.forEach(file => {
      _totalSize += file.size;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateRemove = (file: any, callback: any) => {
    setTotalSize(totalSize - file.size);
    setProgress(0);
    callback();
  };

  const itemTemplate = (file: any, props: any) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div>
            <img
              alt={file.name}
              role="presentation"
              src={document}
              width={50}
            />
            <span className="flex flex-column text-left ml-3">
              {file.name}
              <small>{new Date().toLocaleDateString()}</small>
            </span>
          </div>
          <ProgressBar
            now={progress}
            label={progress}
            style={{ height: '15px', width: '20%' }}
            animated={true}
          />

          <div>
            <Tag
              value={props.formatSize}
              severity="warning"
              className="px-3 py-2"
              style={{ marginRight: 20 }}
            />
            <a onClick={() => onTemplateRemove(file, props.onRemove)}>
              <img alt={file.name} role="presentation" src={trash} width={20} />
            </a>
          </div>
        </div>
      </div>
    );
  };

  const onUpload = async (event: any, uploadType: UploadType) => {
    const userId = localStorage.getItem('userId');
    if (projectData?.projectId && projectData?.domain && userId) {
      const file = event.files[0];
      const input: FileUploadInput = {
        file: file,
        domain: projectData.domain ?? undefined,
        uploadType: uploadType,
        projectId: projectData.projectId,
        userId: userId,
        targetColumn: ''
      };
      try {
        const response = await uploadFile(input, progress =>
          setProgress(progress)
        );
        dispatch(
          setToast({
            show: true,
            title: 'File Uploaded',
            message: 'File uploaded successfully'
          })
        );
        console.log('Upload successful:', response);
      } catch (error) {
        console.error('Upload failed:', error);
      }
    }
  };
  // Calculate and format file size
  // const formatSize = (size: number): string => {
  //   if (size < 1024) return `${size} bytes`;
  //   else if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
  //   else if (size < 1073741824) return `${(size / 1048576).toFixed(2)} MB`;
  //   else return `${(size / 1073741824).toFixed(2)} GB`;
  // };

  // Header template for the FileUpload component
  // const headerTemplate = (options: any) => {
  //   const { className, chooseButton, uploadButton, cancelButton } = options;
  //   const value = totalSize / 10000;
  //
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         backgroundColor: 'transparent',
  //         display: 'flex',
  //         alignItems: 'center'
  //       }}
  //     >
  //       {chooseButton}
  //       {uploadButton}
  //       {cancelButton}
  //     </div>
  //   );
  // };
  // const chooseOptions = {
  //   icon: 'folder-plus',
  //   iconOnly: true,
  //   className: 'custom-choose-btn p-button-rounded p-button-outlined'
  // };
  // const uploadOptions = {
  //   icon: 'pi pi-fw pi-cloud-upload',
  //   iconOnly: true,
  //   className:
  //     'custom-upload-btn p-button-success p-button-rounded p-button-outlined'
  // };
  // const cancelOptions = {
  //   icon: 'pi pi-fw pi-times',
  //   iconOnly: true,
  //   className:
  //     'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'
  // };

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="dataset">
      <Row className="g-0 border-top border-bottom">
        <Col xs={12} sm={3}>
          <Nav className="flex-sm-column border-bottom border-bottom-sm-0 border-end-sm fs-9 vertical-tab h-100 justify-content-between">
            {navItems.map((item, index) => (
              <NavItem
                key={item.label}
                item={item}
                isLast={index === navItems.length - 1}
              />
            ))}
          </Nav>
        </Col>

        <Col xs={12} sm={9}>
          <Tab.Content className="py-3 ps-sm-4 h-100">
            <Tab.Pane eventKey="dataset" className="h-100">
              <div className="d-flex flex-column h-100">
                <h5 className="mb-3 text-body-highlight">Dataset</h5>
                <FileUpload
                  ref={fileUploadRef}
                  name="file_dataset"
                  onUpload={onTemplateUpload}
                  onSelect={onTemplateSelect}
                  // headerTemplate={headerTemplate}
                  // itemTemplate={itemTemplate}

                  itemTemplate={itemTemplate as any}
                  emptyTemplate={
                    <p className="m-0">
                      Drag and drop files to here to upload.
                    </p>
                  }
                  // headerTemplate={headerTemplate}
                  // onUpload={() => setProgress(0)}
                  onProgress={evt => setProgress(evt.progress)}
                  // progressBarTemplate={<div>Uploading: {progress}</div>}
                  customUpload
                  uploadHandler={event => onUpload(event, 'DATA_SET')}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="model" className="h-100">
              <div className="d-flex flex-column h-100">
                <h5 className="mb-3 text-body-highlight">ML Model</h5>
                <FileUpload
                  ref={fileUploadRef}
                  name="file_ml_model"
                  onUpload={onTemplateUpload}
                  onSelect={onTemplateSelect}
                  // headerTemplate={headerTemplate}
                  // itemTemplate={itemTemplate}

                  itemTemplate={itemTemplate as any}
                  emptyTemplate={
                    <p className="m-0">
                      Drag and drop files to here to upload.
                    </p>
                  }
                  // headerTemplate={headerTemplate}
                  // onUpload={() => setProgress(0)}
                  onProgress={evt => setProgress(evt.progress)}
                  // progressBarTemplate={<div>Uploading: {progress}</div>}
                  customUpload
                  uploadHandler={event => onUpload(event, 'ML_MODEL')}
                />
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="llm" className="h-100">
              <div className="d-flex flex-column h-100">
                <h5 className="mb-3 text-body-highlight">LLM Model</h5>
                <FileUpload
                  ref={fileUploadRef}
                  name="file_dataset"
                  onUpload={onTemplateUpload}
                  onSelect={onTemplateSelect}
                  // headerTemplate={headerTemplate}
                  // itemTemplate={itemTemplate}

                  itemTemplate={itemTemplate as any}
                  emptyTemplate={
                    <p className="m-0">
                      Drag and drop files to here to upload.
                    </p>
                  }
                  // headerTemplate={headerTemplate}
                  // onUpload={() => setProgress(0)}
                  onProgress={evt => setProgress(evt.progress)}
                  // progressBarTemplate={<div>Uploading: {progress}</div>}
                  customUpload
                  uploadHandler={event => onUpload(event, 'LLM_MODEL')}
                />
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey="privacy" className="h-100">
              <div className="d-flex flex-column h-100">
                <h5 className="mb-3 text-body-highlight">Privacy Documents</h5>
                <FileUpload
                  ref={fileUploadRef}
                  name="file_dataset"
                  onUpload={onTemplateUpload}
                  onSelect={onTemplateSelect}
                  // headerTemplate={headerTemplate}
                  // itemTemplate={itemTemplate}

                  itemTemplate={itemTemplate as any}
                  emptyTemplate={
                    <p className="m-0">
                      Drag and drop files to here to upload.
                    </p>
                  }
                  // headerTemplate={headerTemplate}
                  // onUpload={() => setProgress(0)}
                  onProgress={evt => setProgress(evt.progress)}
                  // progressBarTemplate={<div>Uploading: {progress}</div>}
                  customUpload
                  uploadHandler={event => onUpload(event, 'POLICY_DOCUMENT')}
                />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default InventoryTab;
