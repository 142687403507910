import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import ProjectsTopSection from 'components/modules/project-management/ProjectsTopSection';
import ProjectListTable, {
  projectListTableColumns
} from 'components/tables/ProjectListTable';
import { defaultBreadcrumbItems } from 'data/commonData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ProjectListActions } from './data-access/+state/project-list.actions';
import { ProjectSelector } from './data-access/+state/project-list.selectors';

const ProjectListView = () => {
  const dispatch = useDispatch();
  const projectListSelector = useSelector(ProjectSelector.allProjects);
  const userId = localStorage.getItem('userId');
  useEffect(() => {
    console.log('USERID', userId);
    if (userId !== null && userId !== undefined) {
      dispatch(
        ProjectListActions.fetchProjectLists({
          userId: userId
        })
      );
    }
  }, []);

  const table = useAdvanceTable({
    data: projectListSelector,
    columns: projectListTableColumns,
    pageSize: 6,
    pagination: false,
    sortable: true
  });

  console.log('projectListSelector', projectListSelector);

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Projects</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({projectListSelector && projectListSelector?.length})
            </span>
          </h2>
          <Link className="btn btn-primary px-5" to="/">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            Add new project
          </Link>
        </div>
        <ProjectsTopSection activeView="list" />
        {projectListSelector && projectListSelector.length && (
          <ProjectListTable />
        )}
      </AdvanceTableProvider>
    </div>
  );
};

export default ProjectListView;
