import { faCalendar, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import CrmStatCard from 'components/cards/CrmStatCard';
import ContactsCreatedChart from 'components/charts/e-charts/ContactsCreatedChart';
import LeadSources from 'components/list-items/LeadSources';
import AdClicks from 'components/modules/crm/AdClicks';
import ContactsBySource from 'components/modules/crm/ContactsBySource';
import DealForecast from 'components/modules/crm/DealForecast';
import LeadConversion from 'components/modules/crm/LeadConversion';
import NewUsersAndLeads from 'components/modules/crm/NewUsersAndLeads';
import RevenueTarget from 'components/modules/crm/RevenueTarget';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectSelector } from '../project-management/project-list/data-access/+state/project-list.selectors';
import { useEffect, useState } from 'react';
import { ProjectListActions } from '../project-management/project-list/data-access/+state/project-list.actions';
import { useParams } from 'react-router-dom';
import { ChartData, Result } from '@shared/types/project.type';

const Crm = () => {
  const activeProject = useSelector(ProjectSelector.activeProject);
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState<ChartData>();
  const [mlChartData, setMlChartData] = useState<ChartData>();

  const { projectId } = useParams<{ projectId: string }>();

  function convertToChartData(results: Result[]): ChartData {
    const xAxis: string[] = [];
    const yAxis: number[] = [];

    results.forEach(result => {
      xAxis.push(result.Attribute);
      yAxis.push(result['Disparity Ratio']);
    });

    return { xAxis, yAxis };
  }

  useEffect(() => {
    const userId = localStorage.getItem('userId');

    console.log(projectId, userId);
    if (projectId && userId) {
      dispatch(
        ProjectListActions.fetchProjectByIdAttempt({
          userId: userId,
          projectId: projectId
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      activeProject &&
      activeProject.feature_results &&
      activeProject.feature_results.llm_results !== undefined &&
      activeProject.feature_results.llm_results !== null
    ) {
      const { xAxis, yAxis } = convertToChartData(
        activeProject.feature_results.llm_results
      );

      setChartData({ xAxis, yAxis });
    }
    if (
      activeProject &&
      activeProject.feature_results &&
      activeProject.feature_results.ml_results !== undefined &&
      activeProject.feature_results.ml_results !== null
    ) {
      const { xAxis, yAxis } = convertToChartData(
        activeProject.feature_results.ml_results
      );

      setMlChartData({ xAxis, yAxis });
    }
  }, [activeProject]);

  return (
    <>
      <Row className="mb-4 gy-3 justify-content-between">
        <Col xxl={6}>
          <h2 className="mb-2 text-body-emphasis">
            {activeProject?.title} - Analysis
          </h2>
          <h5 className="text-body-tertiary fw-semibold mb-4">
            Check your project analysis in one place
          </h5>
          <Row className="g-3 mb-3">
            <Col sm={6} md={4} xl={3} xxl={4}>
              <CrmStatCard
                icon={faPhoneAlt}
                color="primary"
                label="Outgoing call"
                value={3}
                title="Leads Today"
                badgeLabel="+24.5%"
                badgeBg="success"
                footerText="Than yesterday"
              />
            </Col>
            <Col sm={6} md={4} xl={3} xxl={4}>
              <CrmStatCard
                icon={faCalendar}
                color="info"
                label="Outgoing meeting"
                value={12}
                title="This Week"
                badgeLabel="+20.5%"
                badgeBg="warning"
                footerText="Than last week"
              />
            </Col>
            <Col md={4} xl={6} xxl={4}>
              <div className="border-bottom">
                <h5 className="pb-4 border-bottom">Top 5 Lead Sources</h5>
                <LeadSources />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xxl={6} className="mb-6">
          <h3>LLM Model Bias Detection - Disparity Ratios</h3>
          <p className="text-body-tertiary mb-1">
            Payment received across all channels
          </p>
          {chartData && (
            <ContactsCreatedChart
              chartData={chartData}
              style={{ height: 270, width: '100%' }}
            />
          )}
        </Col>
        <Col xxl={6} className="mb-6">
          <h3>ML Model Bias Detection - Disparity Ratios</h3>
          <p className="text-body-tertiary mb-1">
            Payment received across all channels
          </p>
          {chartData && (
            <ContactsCreatedChart
              chartData={mlChartData}
              style={{ height: 270, width: '100%' }}
            />
          )}
        </Col>
        <Col xs={12} xxl={6} className="mb-3 mb-sm-0">
          <ContactsBySource />
        </Col>
        <Col xs={12} xxl={6} className="mb-8">
          <NewUsersAndLeads />
        </Col>
        <Col xs={12} xxl={6}>
          <AdClicks />
        </Col>
        <Col xs={12} xxl={6} className="mb-6 gy-0 gy-xxl-3">
          {activeProject &&
            activeProject.feature_results &&
            activeProject.feature_results.llm_results &&
            activeProject.feature_results.llm_results.length > 0 && (
              <DealForecast
                title={'LLM Model Bias Detection'}
                data={activeProject.feature_results.llm_results}
              />
            )}
        </Col>
        <Col xs={12} xxl={6} className="mb-6 gy-0 gy-xxl-3">
          {activeProject &&
            activeProject.feature_results &&
            activeProject.feature_results.ml_results &&
            activeProject.feature_results.ml_results.length > 0 && (
              <DealForecast
                title={'ML Model Bias Detection'}
                data={activeProject.feature_results.ml_results}
              />
            )}
        </Col>
      </Row>
      <div className="mx-lg-n4">
        <Row className="g-3 mb-9 mt-n7">
          <Col xs={12} xl={5}>
            <LeadConversion />
          </Col>
          <Col xs={12} xl={7}>
            <RevenueTarget />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Crm;
