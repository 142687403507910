import Button from '../../../components/base/Button';
import PageBreadcrumb from '../../../components/common/PageBreadcrumb';
import InventoryTab from '../../../components/tabs/InventoryTab';
import { defaultBreadcrumbItems } from '../../../data/commonData';
import 'react-dropzone-uploader/dist/styles.css';
import {
  Col,
  FloatingLabel,
  Form,
  Nav,
  Row,
  Spinner,
  Tab
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectCreateActions } from './data-access/+state/project-create.actions';
import { useEffect, useState } from 'react';
import { ProjectCreationSelectors } from './data-access/+state/project-create.selectors';
import { setToast } from '../../../components/Toasts/data-access/toast-reducer';
import { Domain } from '@shared/types/project.type';

const CreateNew = () => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [domain, setDomain] = useState<Domain>('Banking');

  const [activeTab, setActiveTab] = useState<string | null>('create');
  const createProjectStatus = useSelector(
    ProjectCreationSelectors.createProjectResponse
  );
  const onSubmit = () => {
    dispatch(
      ProjectCreateActions.createProjectAttempt({
        title,
        description,
        domain
      })
    );
  };
  //
  // const generateAnalysis = () => {
  //   navigate('/');
  // };

  useEffect(() => {
    if (createProjectStatus === 'succeeded') {
      console.log('createProject stats', createProjectStatus);
      dispatch(
        setToast({ show: true, title: 'Project Created', message: 'Success' })
      );
      setActiveTab('uploads');
    } else if (createProjectStatus === 'failed') {
      dispatch(
        setToast({ show: true, title: 'Failed', message: 'Failed to create ' })
      );
    }
  }, [createProjectStatus]);

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <h2 className="mb-4">Create a project</h2>
      <Row>
        <Col xs={12} xl={10}>
          <Tab.Container
            id="basic-tabs-example"
            activeKey={activeTab ?? 'create'}
            onSelect={k => setActiveTab(k)}
          >
            <Nav variant="underline" className="mb-3">
              <Nav.Item>
                <Nav.Link eventKey="create">Project Information</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="uploads">Uploads</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="create">
                <Row as="form" className="g-3 mb-6">
                  <Col sm={6} md={8}>
                    <FloatingLabel
                      controlId="floatingInputGrid"
                      label="Project title"
                    >
                      <Form.Control
                        onChange={e => setTitle(e.target.value)}
                        type="text"
                        placeholder="Project title"
                      />
                    </FloatingLabel>
                  </Col>
                  <Col sm={6} md={4}>
                    <FloatingLabel
                      controlId="floatingSelectTask"
                      label="Select Domain"
                    >
                      <Form.Select
                        onChange={e => setDomain(e.target.value as Domain)}
                      >
                        <option>Select Domain</option>
                        <option value="Banking">Banking</option>
                        <option value="Insurance">Insurance</option>
                        <option value="Healthcare">Healthcare</option>
                        <option value="Manufacturing">Manufacturing</option>
                        <option value="Pharma">Pharma</option>
                        <option value="CPG">CPG</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>

                  <Col xs={12} className="gy-6">
                    <FloatingLabel
                      controlId="floatingProjectOverview"
                      label="Project overview"
                    >
                      <Form.Control
                        onChange={e => setDescription(e.target.value)}
                        as="textarea"
                        placeholder="Leave a comment here"
                        style={{ height: '100px' }}
                      />
                    </FloatingLabel>
                  </Col>

                  <Col xs={12} className="gy-6">
                    <div className="d-flex justify-content-end gap-3">
                      <Button variant="phoenix-primary" className="px-5">
                        Cancel
                      </Button>

                      <Button
                        onClick={onSubmit}
                        variant="phoenix-primary"
                        className="px-5"
                      >
                        {createProjectStatus === 'loading' ? (
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          'Create'
                        )}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="uploads">
                <Row as="form" className="g-3 mb-6">
                  <Col sm="12" md="8" className="flex-1">
                    {/* <h3 className="fw-bolder mb-2 line-clamp-1">
                          Data Set
                        </h3>
                        <p></p>
                        <Dropzone /> */}
                    <InventoryTab />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="gy-6">
                    <div className="d-flex justify-content-end gap-3">
                      <Button variant="phoenix-primary" className="px-5">
                        Skip
                      </Button>
                      <Button
                        variant="phoenix-primary"
                        className="px-5 px-sm-15"
                      >
                        Complete
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </div>
  );
};

export default CreateNew;
