import { FileUploadInput } from '@shared/types/project.type';

export const uploadFile = (
  input: FileUploadInput,
  onProgress: (progress: number) => void
): Promise<never> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const formData = new FormData();
    let END_POINT = '/upload-dataset';
    switch (input.uploadType) {
      case 'DATA_SET':
        END_POINT = '/upload-dataset';
        break;

      case 'POLICY_DOCUMENT':
        END_POINT = '/upload-policy-document';
        break;

      case 'ML_MODEL':
        END_POINT = '/upload-ml-model';
        break;
      case 'LLM_MODEL':
        END_POINT = '/upload-llm-model';
        break;
      default:
        END_POINT = '/upload-dataset';
        break;
    }

    formData.append('file', input.file);
    if (input.domain) {
      formData.append('domain', input.domain);
    }
    formData.append('uploadType', input.uploadType ?? '');
    if (input.targetColumn) {
      formData.append('targetColumn', input.targetColumn);
    }
    formData.append('projectId', input.projectId);
    formData.append('userId', input.userId);

    xhr.open('POST', `${process.env.REACT_APP_API_BASE_URL}/${END_POINT}`);

    xhr.upload.onprogress = event => {
      if (event.lengthComputable) {
        const progress = Math.round((event.loaded * 100) / event.total);
        onProgress(progress);
      }
    };
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(JSON.parse(xhr.response));
      } else {
        reject(new Error(xhr.statusText));
      }
    };

    xhr.onerror = () => reject(new Error('Upload failed'));

    xhr.send(formData);
  });
};
