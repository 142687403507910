import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IToastState {
  title: string;
  message: string;
  show: boolean;
}

const initialState: IToastState = {
  title: '',
  message: '',
  show: false
};

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToast(
      state,
      action: PayloadAction<{ title: string; message: string; show: boolean }>
    ) {
      state.title = action.payload.title;
      state.message = action.payload.message;
      state.show = action.payload.show;
    },
    clearToast(state) {
      state.title = '';
      state.message = '';
      state.show = false;
    }
  }
});

export const { setToast, clearToast } = toastSlice.actions;
export default toastSlice.reducer;
