import { Project, ProjectList } from '@app/shared/types/project.type';
import { createAction } from '@reduxjs/toolkit';
import { ApiError } from '@app/models/api/api-response.interface';

const fetchProjectLists = createAction<{ userId: string }>(
  '[Project List] Fetch Project List Data Attempt'
);

const fetchProjectListsSuccess = createAction<{ projectsList: ProjectList }>(
  '[Project List] Fetching Project List Success'
);

const fetchProjectListsFailed = createAction<{ error: ApiError }>(
  '[Project List] Fetching Project List Failed'
);

const setActiveProject = createAction<{ activeProject: Project }>(
  '[Project List] Set Active Project'
);

const fetchProjectByIdAttempt = createAction<{
  userId: string;
  projectId: string;
}>('[Project List] Fetch Project ByIdAttempt');

const fetchProjectByIdSuccess = createAction<{ projectData: Project }>(
  '[Project List] Fetch Project ByIdAttempt Success'
);

const fetchProjectByIdFailed = createAction(
  '[Project List] Fetch Project ByIdAttempt Failed'
);

export const ProjectListActions = {
  fetchProjectLists,
  fetchProjectListsSuccess,
  fetchProjectListsFailed,
  fetchProjectByIdAttempt,
  fetchProjectByIdSuccess,
  fetchProjectByIdFailed,
  setActiveProject
};
