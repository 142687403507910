import { AxiosError } from 'axios';
import { CreateProjectResponse } from '@shared/types/project.type';
import {
  ApiError,
  AxiosApiResponse
} from '@app/models/api/api-response.interface';
import api from '../../../../../app/api/axios-api';

const createProject = async (
  title: string,
  domain: string,
  description: string
): Promise<AxiosApiResponse<CreateProjectResponse>> => {
  try {
    const response = await api.post(`project/create-project`, {
      title,
      domain,
      description
    });
    return { response };
  } catch (error) {
    return { error: error as AxiosError<ApiError> };
  }
};
export const ProjectCreateService = {
  createProject
};
