import { createAction } from '@reduxjs/toolkit';
import {
  CreateUserInput,
  LoginInput,
  UserResponse
} from '@app/shared/types/user.types';

const fetchUserAccountAttempt = createAction<{ loginInput: LoginInput }>(
  '[User Account] Fetch User Data Attempt'
);

const fetchUserAccountSuccess = createAction<{ userData: UserResponse }>(
  '[User Account] Fetching User Account Success'
);
const fetchUserAccountFailed = createAction(
  '[User Account] Fetching User Account Failed'
);

const createUserAccountAttempt = createAction<{
  createUserInput: CreateUserInput;
}>('[User Account] Create User Account Attempt');

const createUserAccountSuccess = createAction<{ userResponse: UserResponse }>(
  '[User Account] Create User Account Success'
);

const createUserAccountFailed = createAction(
  '[User Account] Create User Account Failed'
);

export const UserAccountActions = {
  fetchUserAccountAttempt,
  fetchUserAccountSuccess,
  fetchUserAccountFailed,
  createUserAccountAttempt,
  createUserAccountSuccess,
  createUserAccountFailed
};
