import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';
import { accountSlice } from '../../pages/authentication/data-access/+state/account.reducer';
import rootSaga from './+state/+root/root.saga';
import { projectListSlice } from 'pages/project-management/project-list/data-access/+state/project-list.reducer';
import { createProjectSlice } from '../../pages/project-management/create-project/data-access/+state/project-create.reducer';
import { toastSlice } from '../../components/Toasts/data-access/toast-reducer';
// import { modelSlice } from './ui/components/model/+state/model.reducer';
// import { notificationSlice } from './ui/components/notifications/+state/notifications.reducer';

//Router Middleware
const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory()
  });

const sagaMiddleware = createSagaMiddleware();

export const rootReducer = combineReducers({
  router: routerReducer,
  [accountSlice.name]: accountSlice.reducer,
  [projectListSlice.name]: projectListSlice.reducer,
  [createProjectSlice.name]: createProjectSlice.reducer,
  [toastSlice.name]: toastSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        /// ignoredActions: ['router/LOCATION_CHANGE'],
      }
    })
      .concat(sagaMiddleware)
      .concat(routerMiddleware)
});

export const history = createReduxHistory(store);

sagaMiddleware.run(rootSaga);
