import { AxiosError } from 'axios';
import api from '../../../../app/api/axios-api';
import {
  ApiError,
  AxiosApiResponse
} from '../../../../app/models/api/api-response.interface';
import {
  CreateUserInput,
  LoginInput,
  UserResponse
} from '@app/shared/types/user.types';

const createAccount = async (
  userRequest: CreateUserInput
): Promise<AxiosApiResponse<UserResponse>> => {
  try {
    const response = await api.post(`auth/create-account`, userRequest);
    return { response };
  } catch (error) {
    return { error: error as AxiosError<ApiError> };
  }
};
const fetchUserAccount = async (
  loginRequestParams: LoginInput
): Promise<AxiosApiResponse<UserResponse>> => {
  try {
    const response = await api.post(`auth/login`, loginRequestParams);
    console.log('response', response);
    return { response };
  } catch (error) {
    return { error: error as AxiosError<ApiError> };
  }
};

export const UserAccountService = {
  createAccount,
  fetchUserAccount
};
