import { AxiosError } from 'axios';

import {
  ApiError,
  AxiosApiResponse
} from '@app/models/api/api-response.interface';
import api from '../../../../../app/api/axios-api';
import { ProjectList } from '@app/shared/types/project.type';

const fetchProjectLists = async (
  userId: string
): Promise<AxiosApiResponse<ProjectList>> => {
  try {
    const response = await api.get(`project/${userId}/all-projects`);
    return { response };
  } catch (error) {
    return { error: error as AxiosError<ApiError> };
  }
};

export const ProjectListService = {
  fetchProjectLists
};
