import { Dropdown } from 'react-bootstrap';

const ActionDropdownItems = () => {
  return (
    <>
      <Dropdown.Item eventKey="1">Upload Pending</Dropdown.Item>
      <Dropdown.Item eventKey="1">Analysis</Dropdown.Item>
      <Dropdown.Item eventKey="2">Report</Dropdown.Item>
      <Dropdown.Item eventKey="2">Dashboard</Dropdown.Item>
    </>
  );
};

export default ActionDropdownItems;
