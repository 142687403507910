import { RootState } from '@shared/store';
import { toastSlice } from './toast-reducer';
import { createSelector } from '@reduxjs/toolkit';

const selectToast = (state: RootState) => state[toastSlice.name];
const showToast = createSelector(selectToast, state => ({
  message: state.message,
  showToast: state.show,
  title: state.title
}));

export const toastSelectors = {
  showToast
};
