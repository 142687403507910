import { UserAccountActions } from '../../../pages/authentication/data-access/+state/account.actions';
import { UserSelector } from '../../../pages/authentication/data-access/+state/account.selectors';
import Button from 'components/base/Button';
import { FormEvent, useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

const SignInForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accountSelector = useSelector(UserSelector.userResponse);
  const [validated, setValidated] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the default form submission

    const form = event.currentTarget;
    if (form.checkValidity()) {
      setValidated(true);
      dispatch(
        UserAccountActions.fetchUserAccountAttempt({
          loginInput: { username: username, password: password }
        })
      );
    } else {
      event.stopPropagation();
    }

    setValidated(true);
  };

  useEffect(() => {
    if (accountSelector) {
      navigate('/project/all-projects');
    }
  }, [accountSelector]);

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Sign In</h3>
        <p className="text-body-tertiary">Get access to your account</p>
      </div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3 text-start">
          <Form.Label>Username</Form.Label>
          <InputGroup hasValidation>
            <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Username"
              aria-describedby="inputGroupPrepend"
              required
              onChange={e => setUsername(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please enter your username.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label>Password</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              type="text"
              placeholder="Username"
              aria-describedby="inputGroupPrepend"
              required
              onChange={e => setPassword(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Password cannot be empty
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Row className="flex-between-center mb-7">
          <Col xs="auto">
            <Form.Check type="checkbox" className="mb-0">
              <Form.Check.Input
                type="checkbox"
                name="remember-me"
                id="remember-me"
                defaultChecked
              />
              <Form.Check.Label htmlFor="remember-me" className="mb-0">
                Remember me
              </Form.Check.Label>
            </Form.Check>
          </Col>
          <Col xs="auto">
            <Link
              to={`/pages/authentication/${layout}/forgot-password`}
              className="fs-9 fw-semibold"
            >
              Forgot Password?
            </Link>
          </Col>
        </Row>
        <Button type={'submit'} variant="primary" className="w-100 mb-3">
          Sign In
        </Button>
      </Form>
      <div className="text-center">
        <Link to={`/sign-up`} className="fs-9 fw-bold">
          Create an account
        </Link>
      </div>
    </>
  );
};

export default SignInForm;
