import {
  faCheckCircle,
  faSquare,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';
import { Result } from '@shared/types/project.type';
import React from 'react';

interface DealForCastTableProps {
  tableData: Result[];
}
const columns: ColumnDef<Result>[] = [
  {
    header: 'Attribute',
    accessorFn: rowData => rowData.Attribute,
    cell: ({ row: { original } }) => {
      const { Attribute } = original;
      return (
        <Link to={''} className="fw-semibold">
          {Attribute}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '100px' },
        className: 'text-start text-body-tertiary'
      },
      cellProps: { className: 'pe-3 white-space-nowrap py-2' },
      footerProps: {
        className: 'text-end px-3 border-bottom-0'
      }
    }
  },
  {
    id: 'biasDetected',
    header: () => (
      <div className="d-inline-flex flex-center gap-2">
        <FontAwesomeIcon icon={faSquare} className="fs-11 text-primary" />
        Bias Detected
      </div>
    ),
    accessorKey: 'Bias Detected',
    cell: ({ row: { original } }) => {
      return (
        <FontAwesomeIcon
          icon={original['Bias Detected'] ? faCheckCircle : faXmark}
          className="fs-20 text-primary-light"
        />
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '95px' },
        className: 'text-end px-3 text-body-tertiary'
      },
      cellProps: {
        className: 'text-end fw-semibold text-body py-2 px-3'
      },
      footerProps: {
        className: 'text-end px-3 border-bottom-0'
      }
    }
  },
  {
    id: 'qualified',
    header: () => (
      <div className="d-inline-flex flex-center gap-2">
        <FontAwesomeIcon icon={faSquare} className="fs-11 text-primary-light" />
        Disparity Ratio
      </div>
    ),
    accessorKey: 'Disparity Ratio',
    cell: ({ row: { original } }) => original['Disparity Ratio'],
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '100px' },
        className: 'text-end px-3 text-body-tertiary'
      },
      cellProps: {
        className: 'text-end fw-semibold text-body py-2 px-3'
      },
      footerProps: {
        className: 'text-end px-3 py-2'
      }
    }
    // footer: ({ table }) =>
    //   currencyFormat(
    //     table
    //       .getFilteredRowModel()
    //       .rows.reduce(
    //         (total, row) => total + (row.getValue('qualified') as number),
    //         0
    //       )
    //   )
  },
  {
    id: 'qualified',
    header: () => (
      <div className="d-inline-flex flex-center gap-2">
        <FontAwesomeIcon icon={faSquare} className="fs-11 text-primary-light" />
        Internal Policy Compliance
      </div>
    ),
    accessorKey: 'Internal Policy Compliance',
    cell: ({ row: { original } }) => original['Internal Policy Compliance'],
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '100px' },
        className: 'text-end px-3 text-body-tertiary'
      },
      cellProps: {
        className: 'text-end fw-semibold text-body py-2 px-3'
      },
      footerProps: {
        className: 'text-end px-3 py-2'
      }
    }
    // footer: ({ table }) =>
    //   currencyFormat(
    //     table
    //       .getFilteredRowModel()
    //       .rows.reduce(
    //         (total, row) => total + (row.getValue('qualified') as number),
    //         0
    //       )
    //   )
  },
  {
    id: 'External Compliance',
    header: () => (
      <div className="d-inline-flex flex-center gap-2">
        <FontAwesomeIcon icon={faSquare} className="fs-11 text-success" />
        External Compliance
      </div>
    ),
    accessorKey: 'External Compliance',
    cell: ({ row: { original } }) => original['External Compliance'],
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '100px' },
        className: 'text-end px-3 text-body-tertiary'
      },
      cellProps: {
        className: 'text-end fw-semibold text-body py-2 px-3'
      },
      footerProps: {
        className: 'text-end px-3'
      }
    }
    // footer: ({ table }) =>
    //   currencyFormat(
    //     table
    //       .getFilteredRowModel()
    //       .rows.reduce(
    //         (total, row) => total + (row.getValue('closed_won') as number),
    //         0
    //       )
    //   )
  },
  {
    id: 'contact_sent',
    header: () => (
      <div className="d-inline-flex flex-center gap-2">
        <FontAwesomeIcon icon={faSquare} className="fs-11 text-info" />
        Risk
      </div>
    ),
    accessorKey: 'Risk',
    cell: ({ row: { original } }) => original.Risk,
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '100px' },
        className: 'text-end text-body-tertiary'
      },
      cellProps: {
        className: 'text-end fw-semibold text-body py-2 ps-3'
      },
      footerProps: {
        className: 'text-end ps-3 pe-0 border-bottom-0'
      }
    }
    // footer: ({ table }) =>
    //   currencyFormat(
    //     table
    //       .getFilteredRowModel()
    //       .rows.reduce(
    //         (total, row) => total + (row.getValue('contact_sent') as number),
    //         0
    //       )
    //   )
  }
];

const DealForecastTable: React.FC<DealForCastTableProps> = ({ tableData }) => {
  const table = useAdvanceTable({
    data: tableData,
    columns,
    pageSize: 5,
    pagination: true,
    selectionColumnWidth: '30px',
    sortable: true
  });

  return (
    <AdvanceTableProvider {...table}>
      <AdvanceTable
        hasFooter
        tableProps={{
          size: 'sm',
          className: 'phoenix-table phoenix-table-bordered fs-9 mb-0 border-top'
        }}
      />
    </AdvanceTableProvider>
  );
};

export default DealForecastTable;
