import { Toast, ToastContainer } from 'react-bootstrap';
import Button from '../base/Button';
import { UilTimes } from '@iconscout/react-unicons';
import { useDispatch, useSelector } from 'react-redux';
import { toastSelectors } from './data-access/toast-selectors';
import { clearToast } from './data-access/toast-reducer';

export const ToastsContainer = () => {
  const toastData = useSelector(toastSelectors.showToast);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(clearToast());
  };
  return (
    <>
      <ToastContainer className="p-3" position={'top-end'}>
        <Toast show={toastData.showToast} delay={3000} autohide>
          <Toast.Header closeButton={false}>
            <strong className="me-auto">{toastData.title}</strong>
            <Button onClick={handleClose} className="ms-2 p-0 ">
              <UilTimes className="fs-7" />
            </Button>
          </Toast.Header>
          <Toast.Body>{toastData.message}</Toast.Body>
        </Toast>
      </ToastContainer>
      ;
    </>
  );
};
