import { AxiosError } from 'axios';

import {
  ApiError,
  AxiosApiResponse
} from '@app/models/api/api-response.interface';
import api from '../../../../../app/api/axios-api';
import { Project } from '@app/shared/types/project.type';

const fetchProjectDataById = async (
  userId: string,
  projectId: string
): Promise<AxiosApiResponse<Project>> => {
  try {
    const response = await api.get(`/project/${userId}/project/${projectId}`);
    return { response };
  } catch (error) {
    return { error: error as AxiosError<ApiError> };
  }
};

export const ProjectDataService = {
  fetchProjectDataById
};
