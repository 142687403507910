import { faEdit, faEllipsis, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UilStopwatch } from '@iconscout/react-unicons';
import Badge from '../../../components/base/Badge';
import Button from '../../../components/base/Button';
import EcomReturningCustomerRate from '../../../components/modules/e-commerce/dashboard/EcomReturningCustomerRate';
import { useMainLayoutContext } from '../../../providers/MainLayoutProvider';
import { useEffect } from 'react';
import { Col, Dropdown, Nav, Row, Tab } from 'react-bootstrap';

const ProjectDetails = () => {
  const { setContentClass } = useMainLayoutContext();

  useEffect(() => {
    setContentClass('px-0 pt-navbar');

    return () => {
      setContentClass('');
    };
  }, []);

  return (
    <Row className="g-0">
      <Col xs={12} xxl={12} className="px-0 bg-body">
        <div className="px-4 px-lg-6 pt-6 pb-9">
          <div className="mb-3">
            <Row className="align-items-center justify-content-between g-3 mb-4">
              <Col md="auto">
                <h2 className="mb-0">Project Insurance Details</h2>
              </Col>
              <Col md="auto" className="d-flex">
                <Button
                  variant="phoenix-secondary"
                  className="px-3 px-sm-5 me-2"
                >
                  <FontAwesomeIcon icon={faEdit} className="me-sm-2" />
                  <span className="d-none d-sm-inline">Upload Files</span>
                </Button>
                <Button
                  variant="phoenix-danger"
                  className="me-2"
                  startIcon={
                    <FontAwesomeIcon icon={faTrash} className="me-2" />
                  }
                >
                  <span>Delete Project</span>
                </Button>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="phoenix-secondary"
                    className="px-3 dropdown-caret-none"
                  >
                    <FontAwesomeIcon icon={faEllipsis} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="py-2" style={{ zIndex: 9999 }}>
                    <Dropdown.Item href="#!">View profile</Dropdown.Item>
                    <Dropdown.Item href="#!">Report</Dropdown.Item>
                    <Dropdown.Item href="#!">
                      Manage notifications
                    </Dropdown.Item>
                    <Dropdown.Item href="#!" className="text-danger">
                      Delete Lead
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>

            <Badge
              variant="phoenix"
              bg="primary"
              iconPosition="end"
              icon={<UilStopwatch size={12} className="ms-1" />}
            >
              Completed
            </Badge>
          </div>
          <Row className="gx-0 gx-sm-5 gy-8 mb-8">
            <Col xs={12} xl={12} xxl={12} className="pe-xl-0">
              <Tab.Container id="basic-tabs-example" defaultActiveKey="bias">
                <Nav variant="underline" className="mb-3">
                  <Nav.Item>
                    <Nav.Link eventKey="bias">Bias Detection</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fairness">Fairness Metrics</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="explainability">
                      Explainability
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="abuse">AI Abuse</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="bias">
                    <EcomReturningCustomerRate />
                  </Tab.Pane>
                  <Tab.Pane eventKey="fairness">
                    Food truck fixie locavore, accusamus mcsweeney's marfa nulla
                    single-origin coffee squid. Exercitation +1 labore velit,
                    blog sartorial PBR leggings next level wes anderson artisan
                    four loko farm-to-table craft beer twee. Qui photo booth
                    letterpress, commodo enim craft beer mlkshk aliquip jean
                    shorts ullamco ad vinyl cillum PBR. Homo nostrud organic.
                  </Tab.Pane>
                  <Tab.Pane eventKey="explainability">
                    Etsy mixtape wayfarers, ethical wes anderson tofu before
                    they sold out mcsweeney's organic lomo retro fanny pack
                    lo-fi farm-to-table readymade. Messenger bag gentrify
                    pitchfork tattooed craft beer, iphone skateboard locavore
                    carles etsy salvia banksy hoodie helvetica. DIY synth PBR
                    banksy irony. Leggings gentrify squid 8-bit cred pitchfork.
                  </Tab.Pane>
                  <Tab.Pane eventKey="abuse">
                    Etsy mixtape wayfarers, ethical wes anderson tofu before
                    they sold out mcsweeney's organic lomo retro fanny pack
                    lo-fi farm-to-table readymade. Messenger bag gentrify
                    pitchfork tattooed craft beer, iphone skateboard locavore
                    carles etsy salvia banksy hoodie helvetica. DIY synth PBR
                    banksy irony. Leggings gentrify squid 8-bit cred pitchfork.
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </div>
      </Col>
      {/* <Col xs={12} xxl={4} className="px-0 border-start-xxl">
        <div className="h-100">
          <div className="bg-light dark__bg-gray-1100 h-100">
            <div className="p-4 p-lg-6">
              <h3 className="text-body-highlight mb-4 fw-bold">
                Recent activity
              </h3>
              <ActivityTimeline data={activityTimelineData} />
            </div>
            <div>
              <div className="px-4 px-lg-6 mb-3">
                <h4 className="mb-0">Files</h4>
              </div>
              <div className="mb-3">
                {attachments.map((attachment, index) => (
                  <FileListItem
                    key={attachment.name}
                    attachment={attachment}
                    className={classNames('px-4 px-lg-6', {
                      'border-top': index === 0
                    })}
                  />
                ))}
              </div>
              <div className="px-5 px-md-6 mb-9">
                <Button
                  variant="link"
                  className="text-decoration-none p-0"
                  startIcon={<FontAwesomeIcon icon={faPlus} className="me-1" />}
                >
                  Add file(s)
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Col> */}
    </Row>
  );
};

export default ProjectDetails;
