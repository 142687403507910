import { UserAccountActions } from '../../../pages/authentication/data-access/+state/account.actions';
import Button from 'components/base/Button';
import { useState } from 'react';
import {
  Col,
  Form,
  Row,
  Spinner,
  Toast,
  ToastContainer
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UserSelector } from '../../../pages/authentication/data-access/+state/account.selectors';
import { UilTimes } from '@iconscout/react-unicons';

const SignUpForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
  const dispatch = useDispatch();

  const createUserRequestStatus = useSelector(
    UserSelector.createUserRequestStatus
  );

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [companyName, setCompanyName] = useState('');

  const handleSignUp = () => {
    dispatch(
      UserAccountActions.createUserAccountAttempt({
        createUserInput: {
          username: username,
          password: password,
          companyName: companyName
        }
      })
    );
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">Sign Up</h3>
        <p className="text-body-tertiary">Create your account today</p>
      </div>

      <div className="position-relative mt-4">
        <hr className="bg-body-secondary" />
        <div className="divider-content-center">or use email</div>
      </div>
      <Form>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="name">Name</Form.Label>
          <Form.Control
            onChange={e => setCompanyName(e.target.value)}
            id="name"
            type="text"
            placeholder="Name"
          />
        </Form.Group>
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Email address</Form.Label>
          <Form.Control
            id="email"
            type="email"
            onChange={e => setUsername(e.target.value)}
            placeholder="name@example.com"
          />
        </Form.Group>
        <Row className="g-3 mb-3">
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="password">Password</Form.Label>
              <Form.Control
                id="password"
                onChange={e => setPassword(e.target.value)}
                type="text"
                placeholder="Password"
              />
            </Form.Group>
          </Col>
          <Col sm={layout === 'card' ? 12 : 6} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="confirmPassword">
                Confirm Password
              </Form.Label>
              <Form.Control
                id="confirmPassword"
                type="text"
                placeholder="Confirm Password"
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Check type="checkbox" className="mb-3">
          <Form.Check.Input
            type="checkbox"
            name="termsService"
            id="termsService"
          />
          <Form.Check.Label
            htmlFor="termsService"
            className="fs-9 text-transform-none"
          >
            I accept the <Link to="#!">terms </Link>and{' '}
            <Link to="#!">privacy policy</Link>
          </Form.Check.Label>
        </Form.Check>
        <Button onClick={handleSignUp} variant="primary" className="w-100 mb-3">
          {createUserRequestStatus === 'loading' ? (
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            'Sign Up'
          )}
        </Button>
        <div className="text-center">
          <Link to={`/`} className="fs-9 fw-bold">
            Sign in to an existing account
          </Link>
        </div>
      </Form>
      <ToastContainer className="p-3" position={'top-end'}>
        <Toast
          show={createUserRequestStatus === 'failed'}
          delay={3000}
          autohide
        >
          <Toast.Header closeButton={false}>
            <strong className="me-auto">Account Failed</strong>
            <small>11 mins ago</small>
            <Button className="ms-2 p-0 ">
              <UilTimes className="fs-7" />
            </Button>
          </Toast.Header>
          <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default SignUpForm;
