import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Replace with your API's base URL
  timeout: 10000 // Optional: Set a timeout for requests
});

// Request interceptor to attach the Authorization header
api.interceptors.request.use(
  config => {
    // Retrieve the access token from local storage
    const token = localStorage.getItem('accessToken');

    if (token) {
      // Attach the token to the Authorization header
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    // Handle the error before the request is sent
    return Promise.reject(error);
  }
);

export default api;
