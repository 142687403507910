import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ProjectListActions } from './project-list.actions';
import {
  ApiError,
  AxiosApiResponse
} from '@app/models/api/api-response.interface';
import { ProjectListService } from '../service/project-list-service';
import { Project, ProjectList } from '@app/shared/types/project.type';
import { ProjectDataService } from '../service/project-by-id-service';

function* fetchProjectLists(
  action: PayloadAction<
    ReturnType<typeof ProjectListActions.fetchProjectLists>['payload']
  >
): Generator<unknown, void, AxiosApiResponse<ProjectList>> {
  const { response, error } = yield call(
    ProjectListService.fetchProjectLists,
    action.payload.userId
  );
  if (response?.data) {
    yield put(
      ProjectListActions.fetchProjectListsSuccess({
        projectsList: response.data
      })
    );
  } else {
    const apiError: ApiError = {
      error: error?.response?.data.error || 'Unknown error',
      message: error?.response?.data.message || 'An error occurred',
      status: error?.response?.status || 500
    };
    yield put(ProjectListActions.fetchProjectListsFailed({ error: apiError }));
  }
}

function* getProjectById(
  action: PayloadAction<
    ReturnType<typeof ProjectListActions.fetchProjectByIdAttempt>['payload']
  >
): Generator<unknown, void, AxiosApiResponse<Project>> {
  try {
    const { response } = yield call(
      ProjectDataService.fetchProjectDataById,
      action.payload.userId,
      action.payload.projectId
    );
    if (response?.data) {
      yield put(
        ProjectListActions.fetchProjectByIdSuccess({
          projectData: response.data
        })
      );
    } else {
      yield put(ProjectListActions.fetchProjectByIdFailed());
    }
  } catch (error) {
    yield put(ProjectListActions.fetchProjectByIdFailed());
  }
}

function* projectListSagas() {
  yield takeLatest(
    ProjectListActions.fetchProjectLists.type,
    fetchProjectLists
  );
  yield takeLatest(
    ProjectListActions.fetchProjectByIdAttempt.type,
    getProjectById
  );
}

export default projectListSagas;
